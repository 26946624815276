import React, { useEffect, useState } from 'react';
import imagee1 from '../../image/1 banner.jpg';
import imagee2 from '../../image/2 banner.jpg';
import imagee5 from '../../image/3 banner.jpg';
import imagee6 from '../../image/ocen.jpg';
import "./Home.css";
import { Link } from 'react-router-dom';
import ServiceArea from '../Servicearea/ServiceArea';
import ServiceHome from '../ServiceHome/ServiceHome';
import { Modal, Button, Form } from 'react-bootstrap';
import OurClient from '../OurClient/OurClient';

const Home = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    const timer = setTimeout(() => {
      handleShow();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className='mainsection'>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active banareimagediv">
              <img src={imagee1} className="d-block w-100" alt="..." />
              <div className='banaretext'>
                <p>Sculpting Success,</p>
                <p>your Path to Seamless Logistics</p>
                <p>Begins Here</p>
              </div>
            </div>
            <div className="carousel-item banareimagediv">
              <img src={imagee2} className="d-block w-100" alt="..." />
              {/* <div className='banaretext'>GET IN TOUCH! WE'RE ALWAYS READY TO ANSWER</div> */}
              <div className='banaretext'>
                <p>Beyond Borders,</p>
                <p>delivering Solutions,</p>
                <p>Not Just Shipments</p>
              </div>
            </div>
            <div className="carousel-item banareimagediv">
              <img src={imagee5} className="d-block w-100" alt="..." />
              {/* <div className='banaretext'>GET IN TOUCH! WE'RE ALWAYS READY TO ANSWER</div> */}
              <div className='banaretext'>
                <p>Efficiency, Reliability,</p>
                <p>Excellence - Every Mile, Every Time</p>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className=' flex-column gap-0'>
          <Modal.Title className='text-center'>Reach Us</Modal.Title>
          <p className='m-0'>Unlock expert guidance for your freight needs</p>
          <p className=' p-0 m-0 text-center'>Schedule your complimentary consultation today and streamline your logistics journey!</p>
        </Modal.Header>
        <Modal.Body>
          <Form action="https://formsubmit.co/accounts@tciindia.co.in" method="POST">
            <Form.Group controlId="formName" className="mb-2">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Name" required />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-2">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Email Address" required />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber" className="mb-2">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="tel" name="mobileNumber" placeholder="Phone Number" required />
            </Form.Group>
            <Form.Group controlId="formMessage" className="mb-2">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" name="message" rows={3} placeholder="Message" required />
            </Form.Group>
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_next" value="https://tciindia.in/contact" />
            <input type="hidden" name="_template" value="table" />
            <Button variant="dark" type="submit">
              Send Message
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <section>
        <div className="container-fluid maindivhomeabout">
          <div className="row">
            <div className="homeaboutdiv">
              <div className="homeabouthead">
                <p>About <span style={{ color: "#003189" }}>Our Company</span></p>
              </div>
            </div>
            <div className="homeaboutmain">
              <div className="homeabouttextdiv">
                <p>"Embark on a seamless global journey with Continental Freight Service (India) Private Limited, your trusted partner in international freight forwarding." Our comprehensive solutions, driven by over 7 years of seasoned expertise, prioritize efficiency, cost-effectiveness, and unparalleled service excellence for every client.</p>
                <button className='homeaboutbutton'><Link to='/about' style={{ textDecoration: "none", color: "white" }}>Read More</Link></button>
              </div>
              <div className='homeaboutimage'>
                <Link to='/about'> <img src={imagee6} alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServiceHome />
      <ServiceArea />
      <OurClient />
      <section>
        <div className="wearebesthome">
          <p className='besthomeheading'>We are <span style={{ color: "#003189" }}>Best at</span></p>
          <div className="besthomefirst">
            <div className="bestchuldfirst">
              <div className="childchildfirst">
                <i className="ri-globe-line"></i>
              </div>
              <div className="childchildsecond">
                <p className='bestchiledheading'>Goods Tracking Support</p>
                <p className='bestchildtext'>Tracking represents an additional layer of security to ensure goods reach their ... and support of Cargo and Shipment Tracking System.</p>
              </div>
            </div>
            <div className="bestchuldfirst">
              <div className="childchildfirst">
                <i className="ri-timer-line"></i>
              </div>
              <div className="childchildsecond">
                <p className='bestchiledheading'>On Time Delivery</p>
                <p className='bestchildtext'>On-time delivery is vital, enhancing customer satisfaction, operational efficiency, and reputation by reliably fulfilling orders promptly and fostering repeat business.</p>
              </div>
            </div>
            <div className="bestchuldfirst">
              <div className="childchildfirst">
                <i className="ri-truck-line"></i>
              </div>
              <div className="childchildsecond">
                <p className='bestchiledheading'>Reaching Large Destinations</p>
                <p className='bestchildtext'>Reaching large destinations involves meticulous planning, resource allocation, and coordination. Efficient logistics, robust infrastructure, and strategic route optimization are crucial for timely and successful deliveries.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
